import { Link } from "gatsby"
import PropTypes from "prop-types"
import React from "react"
import styled from "styled-components"
import GlobalStyles from "./globalStyles"

const HeaderStyles = styled.header`
  background: var(--red);
  margin-bottom: 1.45rem;
  text-align: center;
  color: var(--white);
  padding: 20px;
  -webkit-box-shadow: 0px 2px 51px -15px rgba(0, 0, 0, 0.75);
  -moz-box-shadow: 0px 2px 51px -15px rgba(0, 0, 0, 0.75);
  box-shadow: 0px 2px 51px -15px rgba(0, 0, 0, 0.75);

  div {
    margin: 0 auto;
    max-width: 960;
    padding: 1.45rem 1, 0875rem;
  }

  h1 {
    margin: 0;
  }
`

const Header = ({ siteTitle }) => (
  <HeaderStyles>
    <GlobalStyles />
    <div>
      <h1>{siteTitle}</h1>
    </div>
  </HeaderStyles>
)

Header.propTypes = {
  siteTitle: PropTypes.string,
}

Header.defaultProps = {
  siteTitle: ``,
}

export default Header
